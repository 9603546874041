/* Estilos generales */
.switch-toggle {
  /* display flex es más práctico para alinear elementos en fila */
  display: flex;
  justify-content: center; /* si quieres centrar horizontalmente */
  background: rgb(97, 97, 97);
  border-radius: 20px;
  width: 95%;
}

.switch-toggle input {
  position: absolute;
  opacity: 0;
}

/* Estilos por defecto que se apliquen a todas las opciones */
.switch-toggle input + label {
  flex: 1;
  margin: 0;
  padding: 1px;
  text-align: center;   /* Centrar el texto dentro del label */
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color .2s;
}

/* Cuando el input está checked */
.switch-toggle input:checked + label {
  background: rgb(6, 214, 160);
}

/* Si hay 2 opciones */
.switch-toggle.two-options input + label {
  width: 50%;     /* Cada opción ocupa la mitad del contenedor */
}

/* Si hay 3 opciones */
.switch-toggle.three-options input + label {
  width: 33.33%;  /* Cada opción ocupa un tercio */
}
