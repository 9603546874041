@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Quicksand', sans-serif !important;
}

.parameter-grid {
  text-align: center;
  padding: 10px;  /* Añade un poco de espacio interno para evitar que los textos se peguen a los bordes */
}

.value-badge {
  display: inline-block;
  min-width: 40px;
  padding: 5px;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
}

/* Ajusta la tarjeta principal para evitar desbordamientos */
.card-container {
  max-width: 400px; /* Puedes ajustar este valor dependiendo del diseño */
  overflow: hidden;
  margin: 0 auto; /* Centra la tarjeta en su contenedor */
  border-radius: 25px;
}

/* Asegura que el grid principal no desborde */
.MuiGrid-root {
  box-sizing: border-box;
  max-width: 100%;
}

.pac-container {
  z-index: 1500 !important; /* Asegúrate que el valor sea mayor al del <Dialog> */
}


.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 21px;
  }
/* Estilos generales */
.switch-toggle {
  /* display flex es más práctico para alinear elementos en fila */
  display: flex;
  justify-content: center; /* si quieres centrar horizontalmente */
  background: rgb(97, 97, 97);
  border-radius: 20px;
  width: 95%;
}

.switch-toggle input {
  position: absolute;
  opacity: 0;
}

/* Estilos por defecto que se apliquen a todas las opciones */
.switch-toggle input + label {
  flex: 1 1;
  margin: 0;
  padding: 1px;
  text-align: center;   /* Centrar el texto dentro del label */
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color .2s;
}

/* Cuando el input está checked */
.switch-toggle input:checked + label {
  background: rgb(6, 214, 160);
}

/* Si hay 2 opciones */
.switch-toggle.two-options input + label {
  width: 50%;     /* Cada opción ocupa la mitad del contenedor */
}

/* Si hay 3 opciones */
.switch-toggle.three-options input + label {
  width: 33.33%;  /* Cada opción ocupa un tercio */
}

.table-container {
	display: grid;
	/* grid-template-columns: repeat(7, 1fr); */
	/* Asignar un ancho fijo a la primera columna y el resto distribuir igual */
	grid-template-columns: 0.3fr 1.5fr repeat(5, 1fr);
	grid-gap: 10px;
	gap: 10px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
  }

.table-container-cell {
	display: grid;
	/* grid-template-columns: repeat(7, 1fr); */
	/* Asignar un ancho fijo a la primera columna y el resto distribuir igual */
	grid-template-columns: 0.3fr 1.5fr repeat(5, 1fr);
	grid-gap: 10px;
	gap: 10px;
	padding: 10px;
	/* border: 1px solid #ddd; */
	border-radius: 5px;
  }
  
  .table-row {
	display: contents;
  }
  
  .table-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	/* border-bottom: 1px solid #ddd; */
	text-align: center;
  }
  .table-cell-rows {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 10px;
	/* border-bottom: 1px solid #ddd; */
	text-align: flex-start;
  }
  
  .table-cell:first-child {
	justify-content: flex-start;
  }
  .table-cell-rows:first-child {
	justify-content: flex-start;
  }
  
  .table-cell-rows input,
  .table-cell-rows select {
	width: 100%;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
  }
  
  .table-cell-rows .badge {
	background-color: #3B3C43;
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
  }

  .table-cell-rows .icon {
	background-color: #00CED1;
	color: white;
	padding: 3px 6px;
	border-radius: 8px;
  }
  
  .table-cell .delete-btn {
	background-color: #ff4d4f;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .table-cell .delete-btn:hover {
	background-color: #d32f2f;
  }

  .table-cell-rows .delete-btn {
	background-color: #ff4d4f;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .table-cell-rows .delete-btn:hover {
	background-color: #d32f2f;
  }
  
  @media (max-width: 768px) {
	.table-container {
	  grid-template-columns: 1fr;
	}
  
	.table-cell {
	  justify-content: flex-start;
	  text-align: left;
	  border-bottom: none;
	  border-right: 1px solid #ddd;
	}
  
	.table-cell:last-child {
	  border-right: none;
	}
  }
  
